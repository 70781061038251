<template>
  <div>
    <div class="index">
      <!-- <div class="banner">
        <el-carousel indicator-position="outside">
          <el-carousel-item v-for="(item,index) in swiperImage[0].image_fir " :key="index">
            <img :src="item" alt="" style="height: auto;">
          </el-carousel-item>
        </el-carousel>
      </div> -->
      <Banner/>
      <div class="company_view">
        <div class="l">
          <div class="company-name">{{companyProfile[0].title}}</div>
          <div class="span"></div>
          <div class="company-desc">
            {{$deTag(companyProfile[0].content)}}
          </div>
          <a class="more" href="./about"> <u>More</u> <span class="iconfont icon-next"></span></a>
          <!-- <div class="company-numbers">
            <div class="numbers-item">
              <div class="item-num" data-target="10000" data-duration="3000"><span
                  id="company-area">{{companyProfile[0].area}}</span></div>
              <div class="item-name">Company Area</div>
            </div>
            <div class="numbers-item">
              <div class="item-num" data-target="30000" data-duration="3000"><span
                  id="company-make">{{companyProfile[0].product}}</span></div>
              <div class="item-name">Production Capacity</div>
            </div>
            <div class="numbers-item">
              <div class="item-num" data-target="1.2" data-duration="3000"><span
                  id="company-invest">{{companyProfile[0].invest}}</span></div>
              <div class="item-name">Equipment Investment</div>
            </div>
          </div> -->
        </div>
        <div class="r video-item">
          <iframe class="item-video"   id="dfvideo" ref="videoPlayer"
          :src="companyProfile[0].video_url" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"></iframe>
          <div class="playbtn"></div>
          <div class="mask"></div>
        </div>
      </div>
      <div class="index-card">
        <div class="card-title container">Hot Product
          <router-link class="more" to="/products">more<span class="iconfont icon-next"></span></router-link>
        </div>
        <div class="tabs">
          <ul class="tab-titles">
            <li class="tab-title" :class="{active:activeTab===index}" v-for="(group,index) in groupedData"
              @mouseover="activeTab = index">
              {{group.categoryName}}
            </li>
          </ul>
          <div class="tab-content container">
            <div class="child " 
            :class="{hidden:activeTab!==index}" v-for="(group,index) in groupedData">
              <ul class="list_view"  
              @mouseenter="onMouseEnter"
              @mouseleave="onMouseLeave"
              :style="{ overflow: isHover ? 'auto' : 'hidden' }">
                <li v-for="(item,index) in group.products" :key="item.index">
                  <router-link :to="{name:'productDetail',params:{id:item.productId}}">
                    <div class="item-pic">
                      <div class="item-pic-view">
                        <img :src="item.imageUrl[0]" alt="pic">
                      </div>
                    </div>
                    <div class="item-text">{{item.productName}}</div>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="index-card certificate" v-if="homeCer.length>0">
        <div class="card-title container">{{homeCer[0].title}}</div>
        <div class="card-desc" v-html="homeCer[0].content"></div>
        <div class="certificate-banner">
          <div class="cert_banner swiper-container-initialized swiper-container-horizontal">
            <swiper :options="certificateSwiper">
              <swiper-slide v-for="(item,index) in homeCer[0].image_cer" :key="index">
                <a href="">
                  <img :src="item" />
                </a>
              </swiper-slide>
            </swiper>
            <div class="swiper-pagination"></div>
          </div>
        </div>
        <div class="cert_menu container">
          <div class="menu-item" v-for="(item,index) in homeCer[0].image_aut">
            <img v-lazy="item" />
          </div>
        </div>
        <div class="card-desc">Certified by ISO9001-2015, QS certification, and complies with FDA standards</div>
      </div>
      <div class="index-card" v-if="partnerList.length>0">
        <div class="card-title container">{{partnerList[0].title}}</div>
        <div class="partners container">
          <div class="partners_banner swiper-container-initialized swiper-container-horizontal">
            <swiper :options="partnerSwiper">
              <swiper-slide v-for="(item,index) in partnerList[0].image_par" :key="index">
                <img :src="item" alt="">
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
      <div class="container research">
        <div class="research-top" v-if="HomerdList.length>0">
          <div class="l">
            <div class="l-title">
              <span></span>{{HomerdList[0].title}}
            </div>
            <div class="l-desc">{{$deTag(HomerdList[0].content)}}</div>
          </div>
          <div class="r">
            <div class="r-item">
              <div class="item-title">管理</div>
              <div class="item-subtitle">Manage</div>
            </div>
            <div class="r-item">
              <div class="item-title">生产</div>
              <div class="item-subtitle">Produce</div>
            </div>
            <div class="r-item">
              <div class="item-title">仓储</div>
              <div class="item-subtitle">Storehouse</div>
            </div>
          </div>
         </div>
         <div class="research-bot">
          <div class="pic-list">
            <div class="pic-item" v-for="(item,index) in HomerdList[0].image">
              <img v-lazy="item" alt="">
            </div>
          </div>
          <div class="r">
            <div class="r-title">Specialized coating detection instruments</div>
            <div class="r-subtitle">Specialized Instruments, Professional Technology</div>
            <div class="text-list">
              <div class="text-item">
                <div class="text-icon">
                  <img src="../assets/imgs/shape01.png" alt="">
                </div>
                <div class="text-text">Experienced technical personnel</div>
              </div>
              <div class="text-item">
                <div class="text-icon">
                  <img src="../assets/imgs/shape01.png" alt="">
                </div>
                <div class="text-text">Specialized services help customers create higher value</div>
              </div>
              <div class="text-item">
                <div class="text-icon">
                  <img src="../assets/imgs/shape01.png" alt="">
                </div>
                <div class="text-text">After-sales service team</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container quality" v-if="companyProfile.length>0">
        <div class="quality-title">
          <span></span> {{companyProfile[4].title}}
        </div>
        <div class="quality-list" v-if="controlList.length>0">
          <a class="quality-item" v-for="(item,index) in controlList" :key='index'>
            <div class="item-pic">
              <div class="item-pic-view">
                <img :src="item.image_url" alt="">
              </div>
            </div>
            <div class="item-text">{{item.title}}</div>
          </a>
        </div>
      </div>
      <div class="advantage">
        <div class="advantage_bg">
          <img src="../assets/imgs/bg.png" alt="">
        </div>
        <div class="advantage-list container">
          <div class="list-item">
            <div class="item-text">
              guangdong cailong new material co., ltd. was founded in 2006. Mr. Lin, the founder of the company, has worked in FSPG for more than 20 years and has rich industrial experience. He is currently the Executive Vice Chairman of the Metalized Film Industry of China Plastics Processing Industry Association.
            </div>
            <div class="item-icon">
              <img src="../assets/imgs/ico01.png" alt="">
            </div>
          </div>
          <div class="list-item">
            <div class="item-icon">
              <img src="../assets/imgs/ico04.png" alt="">
            </div>
            <div class="item-text">
              Won the "National High-tech Zone Annual Leading Enterprise", "National High-tech Zone Annual Gazelle Enterprise" and obtained multiple national patents. Participated in the formulation of the national standard "General Quality Rules for Oxide Barrier Transparent Plastic Composite Films and Bags for Food Packaging" GB/T40266-2021, and is also the leading unit in formulating the industry standard "Transparent Aluminum Oxide Coated Film for Packaging".
            </div>
          </div>
          <div class="list-item">
            <div class="item-text">
              Introduced several Japanese and German vacuum aluminum plating machines, stack collapse aluminum plating machines and supporting high-level slitting machines. Equipped with a Class 10,000 dust-free clean workshop, and passed ISO9001, ISO14001, QS certification, in compliance with FDA and MSDS standard requirements.
            </div>
            <div class="item-icon">
              <img src="../assets/imgs/ico02.png" alt="">
            </div>
          </div>
          <div class="list-item">
            <div class="item-icon">
              <img src="../assets/imgs/ico05.png" alt="">
            </div>
            <div class="item-text">
              Products are widely used in color printing packaging, self-adhesive labels, decorative materials, food packaging, pharmaceutical packaging, leather, textiles, electronics, photovoltaics, optoelectronics, thermal insulation materials and other industries.
            </div>
          </div>
          <div class="list-item">
            <div class="item-text">
              With solid technological accumulation, adhering to the concept of technological innovation and management innovation, Foshan Cailong is committed to promoting the rapid development of China's high-barrier packaging film material industry, embarking on a new journey to comprehensively replace similar imported products.

            </div>
            <div class="item-icon">
              <img src="../assets/imgs/ico03.png" alt="">
            </div>
          </div>
          <div class="list-item">
            <div class="item-icon">
              <img src="../assets/imgs/ico06.png" alt="">
            </div>
            <div class="item-text">
              The global top-class WVTR/OTR tester, MOCON PERMSTRAN-W
            </div>
          </div>
        </div>
      </div>
      <div class="index_news">
        <div class="container">
          <div class="l">
            <img src="../assets/imgs/bg06.png">
          </div>
          <div class="r">
            <div class="r-top">
              <div class="r-title">
                <span></span> Enterprise Information
              </div>
              <router-link class="more" to="/news">More <span class="iconfont icon-next"></span></router-link>
            </div>
            <div class="news-list">
              <router-link :to="{name:routeName(item.category_id),params:{id:item.id}}" v-for="(item,index) in newsList.slice(0,4)"
                :key="index">
                <div id="news6" class="list-item" style="margin-bottom: 25px;">
                  <div class="item-time">
                    <div class="item-time-day">
                      {{item.create_time.day}} <span>/</span>
                    </div>
                    <div class="item-time-more">
                      <span>{{item.create_time.year}}</span>
                      <span>{{item.create_time.month}}</span>
                    </div>
                  </div>
                  <div class="item-main">
                    <div class="item-title">{{item.title}}</div>
                    <div class="item-desc" v-html="item.content"></div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="index-card notes">
        <div class="card-title container">Online Message</div>
        <div class="notes-form container">
          <div class="two-input">
            <div class="form-input">
              <div class="form-input-label">
                <span class="iconfont icon-name"></span>
                <span>Name：</span>
              </div>
              <div class="form-input-value">
                <input type="text" v-model="btnForm.name">
              </div>
            </div>
            <div class="form-input">
              <div class="form-input-label">
                <span class="iconfont icon-phone"></span>
                <span>Phone (Required)：</span>
              </div>
              <div class="form-input-value">
                <input type="text" v-model="btnForm.phone">
              </div>
            </div>
          </div>
          <div class="form-input">
            <div class="form-input-label">
              <span class="iconfont icon-mail"></span>
              <span>Email：</span>
            </div>
            <div class="form-input-value">
              <input type="text" v-model="btnForm.email">
            </div>
          </div>
          <div class="form-textarea">
            <div class="form-input-label">
              <span class="iconfont icon-note"></span>
              <span>Content：</span>
            </div>
            <div class="form-textarea-value">
              <textarea name="" id="" cols="30" rows="4" v-model="btnForm.content"></textarea>
            </div>
          </div>
          <a href="javascript:;" class="form-submit" @click="btnSubmit()">
            Free Sample Request 
          </a>
        </div>
      </div>
    </div>
    <div id="backTop">
      <span class="iconfont icon-fanhuijiantou-copy"></span>
    </div>
  </div>
</template>
<script>
  import Banner from '@/components/banner/index.vue'
  export default {
    components:{
      Banner
    },
    data() {
      return {
        isHover: false,
        selectedIndex:0,
        keyword: '',
        showHot: [],
        category: [],
        activeTab: 0,
        profileList: [],
        advantage: [],
        controlList: [],
        productCategory: [],
        homeCer:[],
        partnerList:[],
        HomerdList:[],
        rdCenterList:[],
        swiperImage: [],
        homeSwiper: [],
        companyProfile: [],
        productHotList: [],
        groupedData: [],
        newsList: [],
        btnForm: {},
        certificateSwiper: {
          loop: true,
          autoHeight: true,
          speed: 1000,
          autoplay: true,
        },
        partnerSwiper: {
          loop: true,
          autoHeight: true,
          autoPlay: true,
          speed: 1000,
          delay: 3000,
          slidesPerView: 'auto',
          autoplay: {
            disableOnInteraction: false
          },
        }
      }
    },
    mounted() {
      this.getProfile();
      this.getProduct();
      this.getControl();
      this.getHomeCer();
      this.getNews();
      this.getPartner();
      this.getHomerd();
    },
    methods: {
      onMouseEnter() {
      this.isHover = true;
    },
    onMouseLeave() {
      this.isHover = false;
    },
       //获取首页资质证书
       async getHomeCer() {
        const res = await this.$request.get("/ht/homecertification/getPaginate");
        if (res.data.code === 0) {
          this.homeCer = res.data.data;
          this.homeCer[0].image_cer = this.homeCer[0].image_cer.split(',')
          this.homeCer[0].image_aut = this.homeCer[0].image_aut.split(',')
        }
      },
      //获取首页合作伙伴
      async getPartner() {
        const res = await this.$request.get("/ht/partner/getPaginate");
        if (res.data.code === 0) {
          this.partnerList = res.data.data.data;
          this.partnerList[0].image_par = this.partnerList[0].image_par.split(',')
        }
      },
      //获取研发中心
      async getHomerd() {
        const res = await this.$request.get("/ht/Homerd/getPaginate");
        if (res.data.code === 0) {
          this.HomerdList = res.data.data.data;
          this.HomerdList[0].image=this.HomerdList[0].image.split(',')
        }
      },
      //获取公司简介和各种一级标题
      async getProfile() {
        const res = await this.$request.get('/ht/Profile/getPaginate');
        if (res.data.code === 0) {
          this.companyProfile = res.data.data.data     
        }
      },
      //获取热门产品
      async getProduct() {
        const res = await this.$request.get('/ht/product/getHot');
        if (res.data.code === 0) {
          this.productHotList = res.data.data;
          this.productHotList.reverse()
          this.productHotList.forEach((item) => {
            item.image_url = item.image_url.split(',')
          })
        }
        const grouped = this.productHotList.reduce((acc, item) => {
          const categoryKey = item.category_name;
          if (!acc[categoryKey]) {
            acc[categoryKey] = {
              categoryName: item.category_name,
              products: [],
            };
          }
          acc[categoryKey].products.push({
            productName: item.name,
            imageUrl: item.image_url,
            id: item.category_id,
            productId: item.id
          });
          return acc;
        }, {});
        this.groupedData = Object.values(grouped);
      },
      //获取企业资讯消息
      async getNews() {
        const res = await this.$request.post("/ht/News/getPaginate", {
          fil: 2,
        });
        if (res.data.code === 0) {
          this.pageData = res.data.data;
          this.newsList = res.data.data;
          //是否显示
          this.newsList = this.newsList.filter(item => item.is_hot === 1 && item.is_show === 1);
        }
        //整理时间
        this.newsList.newsTime = this.newsList.map((item, index) => {
          let obj = {};
          let time1 = item.create_time.split(' ');
          let time = time1[0].split('-');
          obj.year = time[0];
          obj.month = time[1];
          obj.day = time[2]
          return item.create_time = obj
        })
        
      },
     //判断是哪个分类的资讯
      routeName(type){
         switch (type) {
          case 1:
            return 'newsDetail'
          case 2:
            return 'reportDetail'
          case 3:
            return 'exhibitionDetail'
          default:
            return 'newsDetail';
         }
      },
      //获取质量控制热门
     async getControl() {
        const res = await this.$request.post('/ht/control/getpaginate',{
          fil:1
        });
        if (res.data.code === 0) {
          this.controlList = res.data.data.data.filter(item => item.is_hot === 1 )
        }
      },
      //提交检索
      async btnSubmit() {
        this.loading = true;
        let res = await this.$request.post('/ht/online/add', this.btnForm)
        this.loading = false
        if (res.data.code === 0) {
          this.$message.success("提交成功")
        } else {
          this.$message.error("提交失败")
        }
      }

    },
  }
</script>
<style lang="scss">
  .el-carousel__container {
    height: 850px !important;
  }
</style>